import React, {KeyboardEvent, useEffect, useRef, useState} from 'react'
import { Alert, Col, Container, Row, Button } from 'react-bootstrap';
import {ApiResponseHandler, Message, Nullable, Chat} from '../@types';
import Loader from '../component/app/loader';
import ChatDisplay from '../component/chat/chatDisplay';
import { useAccessToken } from '../hook/accessTokenHook';
import ChatList from "../component/chat/chatList";
import { useAppDispatch, useAppSelector } from '../hook/appHook';
import { appendChatMessage, loadNewChat, updateActiveChat} from '../state/chat/chatState';
import {Size} from '../util/bootstrapUtil';
import { handleUserChatMessage, saveChat } from '../state/action/chat/chatAction';


export interface ChatContainerProps {
    isNavigateOnChange : boolean
}


const ChatContainer:React.FC<ChatContainerProps> = ({isNavigateOnChange}) => {
    const accessToken = useAccessToken();
    const dispatch = useAppDispatch();
    const {isInitialized, activeChat, chats, isLoading, selectedChatTimestamp} = useAppSelector((state) => state.ChatState);
    const [messageInput, setMessageInput] = useState<string>("");
    const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
    const [error, setError] = useState<Nullable<string>>(null);
    const chatInput = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (isInitialized) {
            newChat();
        }
    }, [isInitialized]);

    useEffect(() => {
        enableFocus();
    }, [selectedChatTimestamp]);

    function handleChatMessage():void {
        if (!messageInput.length) {
            return;
        }

        const newUserMessage:Message = {role: 'user', content:[{type : 'text', text : messageInput}]};
        dispatch(appendChatMessage(newUserMessage));

        setIsMessageLoading(true);

        const handler:ApiResponseHandler<Array<Message>> = {
            onSuccess : (messages:Array<Message>) => {
                resetInput();
                saveConversation(messages);
            },
            onError : (error:string) => setError(error),
            onComplete : () => setIsMessageLoading(false)
        };

        dispatch(handleUserChatMessage(accessToken ?? "", newUserMessage, handler));
    };

    function saveConversation(messages:Array<Message>):void {
        if (!messages.length || !accessToken || isLoading || !messageInput.length) {
            return;
        }

        const handler:ApiResponseHandler<Chat> = {
            onSuccess : (savedChat:Chat) => {
                resetInput();
            },
            onError : (error:string) => setError(error),
        };

        dispatch(saveChat(accessToken ?? "", messages, handler));
    }

    function enableFocus():void {
        setTimeout(() => {
            chatInput.current?.focus();
        }, 50);
    }

    function resetInput():void {
        setMessageInput("");
        enableFocus();
    }

    function newChat():void {
        const lastChat = chats[chats.length - 1];
        if (!!lastChat && !lastChat.messages.length) {
            dispatch(updateActiveChat(lastChat));
            return;
        }

        dispatch(loadNewChat());
        setMessageInput("");
    }

    const onMessageInputChanged = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        const messageFormInput:string = event.currentTarget.value;
        setMessageInput(messageFormInput);
    }

    function onReturnKey(event:KeyboardEvent<HTMLTextAreaElement>):void {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleChatMessage();
            event.preventDefault();
        }
    }

    return (
        <>
            {!!error &&
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            }
            <Container fluid={true}>
                <Row>
                    <Col md={3} className="d-md-block d-none p-0 border-1 border-end">
                        <ChatList />
                    </Col>
                    <Col md={9} className="p-0">
                        <ChatDisplay isLoading={isMessageLoading} />
                        <div className="mb-chat-input-container">
                            <div className="d-flex gap-1">
                                <textarea 
                                        disabled={isLoading || isMessageLoading}
                                        ref={chatInput}
                                        value={messageInput} 
                                        rows={3} 
                                        style={{flex: 1}} 
                                        id="messageInput" 
                                        placeholder="Chat with MB AI Agent..." 
                                        onChange={onMessageInputChanged}
                                        autoFocus
                                        onKeyPress={onReturnKey} />
                                <Button 
                                        className="ms-auto mb-button pb-0" 
                                        size={Size.SMALL} 
                                        type="submit" 
                                        disabled={isLoading || isMessageLoading || !messageInput.length} 
                                        onClick={handleChatMessage}>
                                    Send
                                </Button>
                                <Button 
                                        className="ms-auto mb-button pb-0" 
                                        size={Size.SMALL}  
                                        type="button" 
                                        disabled={isLoading || isMessageLoading || !activeChat?.messages.length} 
                                        onClick={newChat}>
                                    New Chat
                                </Button>
                            </div>
                            <small>Use Shift + Enter/Return to add a new line</small>
                        </div>
                    </Col>
                </Row>
                {isLoading && <Loader />}
            </Container>
        </>
    );
};

export default ChatContainer;